.nav-link {
    border-bottom-style: solid !important;
    border-bottom-width: 1px;
    border-bottom-color: rgb(209, 209, 209);
    margin: 10px;
    cursor: pointer;
}
#navbar-container {
    margin-top: 0;    
    --bs-navbar-toggler-focus-width: 0rem;
}
.navbar-nav {
    --bs-nav-link-hover-color: #113f67 !important;
    font-size: 1.31538rem;
    font-weight: 700;
    font-family: 'Roboto' sans-serif;
    color: white;
}
.navbar-toggler { 
    --bs-navbar-toggler-icon-bg: url('../../assets/menu.png');
  
}
.navbar-toggler:hover { 
   background-color: #bbbbbb4e;
}
.offcanvas{
    --bs-offcanvas-bg: #aab1da !important;
    --bs-offcanvas-padding-x: 3rem !important;
    --bs-offcanvas-padding-y: 0.8rem !important;
    opacity: 1.0;
}
